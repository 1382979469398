<template>
  <div style="padding: 20px;">
    <div>
      <div class="title">
        {{ $route.meta.title }}
      </div>

      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px;">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">
              筛选条件
            </div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
              :model="where"
              label-width="90px"
              class="ele-form-search"
              @keyup.enter.native="reload"
              @submit.native.prevent>
              <el-row :gutter="15">
                <el-col :lg="6" :md="12">
                  <el-form-item label="表单名称">
                    <el-input v-model="where.name" placeholder="请输入表单名称" clearable></el-input>
                  </el-form-item>
                </el-col>
                <el-col :lg="4" :md="12" style="text-align: center;">
                  <div class="ele-form-actions">
                    <el-button
                      type="primary"
                      class="ele-btn-icon"
                      @click="QueryList">查询
                    </el-button>
                    <el-button @click="empty">清空</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">
                数据列表
              </div>
            </div>
            <div>
              <!--<el-button>导出数据</el-button>-->
              <!--<el-button @click="ListSetup()">列表设置</el-button>-->
            </div>
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <my-table
              ref="myTable"
              :columns="columns"
              :tableData="List"
              :cellStyle="cellStyle"
              :headerCellStyle="headerCellStyle"
              :rowClassName="rowClassName"
              :total="total"
              :customsFromWhere="where"
              :loading="loading"
              @select="select"
              @ChangeSize="ChangeSize"
              @currentChange="currentChange"
            >
              <!-- 不使用数据可不接收scope值 -->
              <template v-slot:operationSlot= "{ scope }">
                <el-link type="success" :underline="false" @click="FormDesign(scope.row)"  v-auths="[`${$config.uniquePrefix}system:formdesign:put`]">设计表单</el-link>
                <el-link type="primary" :underline="false" @click="allocation(scope.row)" v-auths="[`${$config.uniquePrefix}system:formdesign:info`]">查看组件</el-link>
              </template>
            </my-table>
          </div>
        </div>
      </el-card>

      <!--列表设置弹窗-->
      <div class="dialog">
        <el-dialog
          title="列表设置"
          :visible.sync="ListSetupShow"
          width="60%"
          center
          :before-close="handleClose">
        <span>
           <PackageSortElement :AllParameters="columns"   @change="SortList" @show="dialogshow"></PackageSortElement>
        </span>
        </el-dialog>
      </div>
    </div>

    <!--  自定义表单-->
    <!--<div v-if="FormShow == true" style="background-color: #fff;height: 92vh;">-->
    <!--  <div style="padding: 10px 10px;text-align: right;">-->
    <!--    <el-button type="primary" @click="FormShow = false">返回</el-button>-->
    <!--  </div>-->
    <!--  <fc-designer ref="designer"/>-->
    <!--</div>-->

    <!-- 分配权限弹窗 -->
    <Assignpermissions v-if="showEdit === true" :data="current" :visible.sync="showEdit" @done="queryDone"/>

    <!--抽屉-->
    <el-drawer
      title="分配"
      size="50%"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="drawerhandleClose">
      <div style="padding: 20px;">
        <my-table
          ref="myTable"
          :columns="columns1"
          :tableData="List1"
          :loading="loading1"
        >
          <!-- 不使用数据可不接收scope值 -->
          <template v-slot:operationSlot= "{ scope }">
            <el-link type="primary" :underline="false" @click="setPermission(scope.row)">设置权限</el-link>
          </template>
        </my-table>
      </div>
    </el-drawer>

  </div>
</template>

<script>
// 引入自定义表格组件
import MyTable from "@/components/MyTable"
// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";

//引入分配权限组件
import Assignpermissions from './assignpermissions.vue'

// 权限
import {ShowTable} from '@/utils'
// 引入的接口
import {diyform, form_assistlist, getlog} from "@/api/system";

export default {
  name: 'index',
  // 支柱道具属性
  props: {},
  // 组件生效
  components: {
    MyTable,
    PackageSortElement,
    Assignpermissions
  },
  // Data数据
  data() {
    return {
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "表单编号",
          // type: "index",
          // width: "70"
          prop: "uniques",
          isShow: true
        },
        {
          label: "表单名称",
          // type: "index",
          // width: "70"
          prop: "title",
          isShow: true
        },
        {
          label: "操作",
          width: "200",
          slot: "operationSlot",
          isShow: true
        },
      ],

      // 列表设置弹窗
      ListSetupShow:false,

      // 表单设计
      FormShow: false,

      // 分配权限
      drawer: false,
      direction: 'rtl',
      showEdit:false,
      current:null,

      // 表格数据
      List1: [],
      //加载动画
      loading1:true,
      // 表头数据
      columns1: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "组件ID",
          // type: "index",
          // width: "70"
          prop: "id",
          isShow: true
        },
        {
          label: "字段ID",
          prop: "field",
          isShow: true
        },
        {
          label: "组件label",
          prop: "label",
          isShow: true
        },
        {
          label: "组件类型",
          prop: "type",
          isShow: true
        },
        {
          label: "操作",
          width: "200",
          slot: "operationSlot",
          isShow: true
        },
      ],

      //唯一标识
      uniques:'',


    }
  },
  // 计算属性 类似于 data 概念
  computed: {
  },
  // 监控 data 中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created() {
  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    //接收设计表单页面传递的数据
    window.addEventListener('message', event =>{
      // 执行操作
      console.log('刷新');
      this.getList();
      if (event.data === 'refresh') {

      }
    });

    this.getList();
  },

  destroyed() {
    console.log('刷新');
    this.getList();
  },

  // 方法集合
  methods: {
    getList() {
      diyform(this.where).then(async (res) => {
        this.total = res.data.count;
        this.List = res.data.list;
        // this.List = [];
        // let data = {
        //   id:'1',
        //   name:'订单表'
        // }
        // this.List.push(data);
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },
    // 查询条件
    QueryList(){
      this.getList();
    },
    // 清空
    empty(){
      this.where = {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      }
      this.getList();
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false
      } else {
        return true
      }
    },

    // 删除
    del(row){
      console.log(row);
      this.$utils.confirm("确定要删除吗？", function () {
        // 执行一些需要的逻辑
        console.log('点击确定')
      }, "确定", 'warning');
    },

    // 点击列表设置
    ListSetup(){
      this.ListSetupShow = true;
    },
    // 列表设置关闭
    handleClose(){
      this.ListSetupShow = false;
    },
    //事件  拿到从组件内部处理的数据顺序并赋值
    SortList(value){
      // 接收子组件传递的值替换当前列表显示顺序
      this.columns = value;
    },
    dialogshow(value){
      this.ListSetupShow = value;
    },

    // 点击设计表单
    FormDesign(row){
      console.log('设计表单');
      // this.FormShow = true;
      // this.$router.push('/FormParser')
      let pathHerf = this.$router.resolve({
          path:'/FormParser', // 你自己要跳转的路径
          query:{
            uniques: row.uniques  // query 要传递携带的参数，如：当前传递的是所需id
      }
    })
      window.open(pathHerf.href, '_blank');
      // window.open('/FormParser')
    },




    // 抽屉关闭
    drawerhandleClose(){
      this.drawer = false;
      this.getList();
    },
    //查看组件
    allocation(row){
      // this.showEdit = true;
      // this.current = row;
      // console.log(row.uniques)
      this.uniques = row.uniques;
      // 获取抽屉数据
      this.getList1(row.uniques)
      // 显示抽屉
      this.drawer = true;
    },
    //获取抽屉数据
    getList1(uniques){
      form_assistlist(uniques).then(res => {
        console.log(res)
        this.List1 = res.data.list;
        this.loading1 = false;
      })
    },

    //点击设置权限
    setPermission(row){
      this.current = row;
      this.showEdit = true;
    },

    // 分配权限弹窗传递的事件
    queryDone(){
      // 获取表格数据
      this.getList();
      // 获取分配权限表格数据
      this.getList1(this.uniques);
    },


  },
}
</script>

<style scoped lang="scss">
.title{
  font-size: 24px;
  font-weight: 500;
  color: #707070;
  margin-bottom: 20px;
}

.condition{
  .condition_t{
    display: flex;
    align-items: center;
  }
  .condition_t_icon{
    width: 5px;
    height: 19px;
    background: #ff9b05;
  }
  .condition_t_text{
    font-size: 20px;
    font-weight: 700;
    color: #202033;
    margin-left: 15px;
  }
}
</style>
